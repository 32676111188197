@import '../../styles/base/variables';

.wrapper {
  &.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.horizontal {
      display: flex;
    }

    &.vertical {
      display: block;
    }

    :global {
      .ant-radio-group {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-auto-flow: column;
        column-gap: 7px;
        width: 190px;
        flex: 1 0 190px;
        transform: none;
        white-space: nowrap;
        border: 1px solid $gray20;
        border-radius: 4px;
        margin: 8px 0 7px;
      }

      .ant-radio-button-wrapper {
        height: 32px;
        line-height: 32px;
        background-color: transparent;
        border: none;
        margin: 3px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray90;
        padding: 0 10px;
        user-select: none;
        font-weight: 700;

        &.ant-radio-button-wrapper-checked {
          background-color: $white;
          color: $gray90;
          border: none;
          box-shadow: 2px 2px 20px rgba(0, 28, 52, 0.2);
          border-radius: 3px;

          &:not(.ant-radio-button-wrapper-disabled) {
            &:hover {
              color: $gray90;
              background: $white;
              border-color: transparent;
            }

            &:focus-within {
              box-shadow: 2px 2px 20px rgba(0, 28, 52, 0.2);
            }
          }
        }

        &.ant-radio-button-wrapper-disabled {
          color: $gray40;

          :hover {
            color: $gray40 !important;
          }
        }

        &:not(:first-child)::before {
          left: -4px;
          background-color: $gray20;
        }

        & > span:not(.ant-radio-button) {
          overflow: hidden;
        }

        &:not(.ant-radio-button-wrapper-disabled):hover {
          color: $bright-navy-blue;
        }

        &:first-of-type {
          margin-right: 0;
        }

        &:last-of-type {
          margin-left: 0;
        }
      }
    }
  }

  &.buttonsWithIcon {
    :global {
      div.ant-radio-group.ant-radio-group-solid {
        transform: none;
        margin: 0;
      }
      .ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item {
        border: 2px solid var(--gray5);
        border-radius: 4px;
        height: auto;
        margin: 0;
        padding-top: 1px;
        padding-bottom: 1px;
        box-shadow: none;
        color: var(--al-mid-gray);
        font-weight: 400;
        background-color: var(--white);
        .saturn-icon {
          top: 5px;
          color: var(--primary-color);
        }

        .normal {
          display: inline-block;
        }
        .filled {
          display: none;
        }

        &:hover {
          border-color: var(--primary-color);
          color: var(--al-mid-gray);
          .normal {
            display: none;
          }
          .filled {
            display: inline-block;
          }
        }
      }
      .ant-radio-group.ant-radio-group-solid {
        border: none;
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        display: none;
      }

      .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
        background-color: var(--primary-color);
        border: none;
        box-shadow: none;
        color: var(--white);
        .saturn-icon {
          color: var(--white);
        }

        .normal {
          display: none;
        }
        .filled {
          display: inline-block;
        }
        &:hover {
          .normal {
            display: none;
          }
          .filled {
            display: inline-block;
          }
        }

        &:not(.ant-radio-button-wrapper-disabled) {
          &:hover {
            background-color: var(--royal-blue-dark);
            color: var(--white);
          }
          &:focus-within {
            box-shadow: none;
          }
        }
      }
    }
  }

  :global {
    .ant-radio-inner {
      width: 20px;
      height: 20px;
      border-width: 2px;

      &::after {
        width: 12px;
        height: 12px;
        background-color: $white;
        margin-top: -6px;
        margin-left: -6px;
        border-radius: 12px;
        top: 50%;
        left: 50%;
      }
    }

    .ant-radio-disabled .ant-radio-inner::after {
      background-color: var(--white);
    }

    .ant-radio-checked {
      .ant-radio-inner {
        border-color: $primary-color;
        background-color: $primary-color;
        &::after {
          transform: scale(0.5);
        }
      }
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: $primary-color !important;
    }

    .ant-radio-wrapper:hover .ant-radio-wrapper,
    .ant-radio-wrapper:hover .ant-radio-inner {
      border-color: #d9d9d9;
    }

    .ant-radio-wrapper-checked:hover .ant-radio-wrapper,
    .ant-radio-wrapper-checked:hover .ant-radio-inner {
      border-color: $primary-color;
    }

    .ant-radio-wrapper .ant-radio-checked::after {
      border: none;
    }

    .ant-radio-wrapper.block {
      display: flex;
      width: max-content;
      span.ant-radio + * {
        padding-inline-end: 0;
        & > div {
          grid-template-columns: auto 24px;
          column-gap: 10px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    .saturn-icon {
      margin-top: 0;
      justify-content: center;
    }

    .saturn-radio-title {
      padding-right: 15px;
      display: inline-block;
      font-weight: bold;
    }
  }
}
