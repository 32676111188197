.wrapper {
  display: flex;
  column-gap: 28px;
}

.enableCallback {
  display: flex;
  column-gap: 16px;
  padding-bottom: 28px;
}

.disableMargins {
  margin: 0px;
}

.popupVisibility {
  font-weight: 700;
  display: flex;
  column-gap: 16px;
  padding-bottom: 28px;
}

.timeoutInput {
  width: 448px;
}

.longInputs {
  width: 744px;
}

.image_container {
  display: block;
}
