.main {
  flex: 1 1 auto;
  padding: 30px;

  :global {
    .flex {
      display: flex;
      justify-content: space-between;
    }
  }
}
.container {
  display: flex;
  height: 100%;
  min-height: 100vh;
}
.navigationBar {
  flex: 0 0 260px;
}

.loginWrapper {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 0;
}
