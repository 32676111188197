.wrapper {
  display: grid;
  grid-template-columns: 265px auto;
  grid-gap: 20px;
  .image_container {
    height: 225px;
  }
}
.inputsWrapper {
  display: grid;
  grid-gap: 20px;

  > div {
    margin: 0;
  }
  .textArea_wrapper {
    display: grid;
    grid-template-columns: auto 47px;
    align-items: center;
  }
}
