.width-limiter {
  &--400 {
    max-width: 400px;
  }
}

// it should be bigger than z-index in Modal in MedicalConditionInsured component
.ReactModal__Overlay {
  z-index: 110;
}
