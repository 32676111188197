@import '../../../styles/variables';

.modal {
  position: relative;
  :global {
    .ant-modal-close-icon svg {
      color: #73777c;
    }
  }
}
.modalTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.modalSubtitle {
  font-size: 14px;
  line-height: 20px;
  color: $gray80;
}

.uploader {
  margin-bottom: 25px;
  .title {
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
  }
  .subtitle {
    font-size: 14px;
    line-height: 20px;
    color: $gray70;
    margin-bottom: 0;
  }
}
.modalErrorText {
  color: $error-color;
}
.checkboxWithError {
  :global {
    .ant-checkbox-inner {
      border-color: $error-color;
    }
  }
}

.divider {
  position: absolute;
  width: 100%;
  left: 0;
  height: 1px;
  background: $gray15;
  z-index: 100;
}

.fileRow {
  background: $gray60;
  display: flex;
  padding-right: 10px;
  align-items: center;
  .name {
    color: $primary-color;
    margin-left: 8px;
    margin-right: 8px;
  }
  .trash {
    flex-shrink: 0;
    width: 12px;
    height: auto;
    margin-left: auto;
    cursor: pointer;
  }
}

.submit {
  margin-top: 21px;
}
