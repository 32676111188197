@import '../../../styles/variables';

.content {
  padding: 30px 0;
}

.wrapper {
  display: grid;
  grid-template-columns: 265px auto;
  grid-gap: 20px;
  .image_container {
    height: 225px;
  }
}
.inputsWrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-rows: 58px 75px;

  > div {
    margin: 0;
  }
  .textArea_wrapper {
    display: grid;
    grid-template-columns: auto 47px;
    align-items: center;
  }
}

.formItem {
  margin: 0;
}
