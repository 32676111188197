@import '../../../styles/variables';

.buttonsContainer {
  display: flex;
  gap: 28px;
  margin-bottom: 28px;
  justify-content: space-between;
  & > div {
    flex-grow: 1;
  }
}

.button {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  background: white;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  font-size: 16px;
  line-height: 24px;
  gap: 8px;
  align-items: center;
}
