.main {
  flex: 1 1 auto;
  padding: 30px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(249, 249, 249, 1) 100%);

  :global {
    .flex {
      display: flex;
      justify-content: space-between;
    }
    .ant-tabs-tabpane {
      .saturn-collapse {
        .ant-collapse-extra {
          position: static;
          top: auto;
          bottom: auto;
          right: auto;
          height: 100%;
          display: flex;
          align-self: center;
        }
      }
    }
  }
}

.container {
  display: flex;
  height: 100%;
  min-height: 100vh;
}

.navigationBar {
  flex: 0 0 260px;
}

.loginWrapper {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 0;
}
