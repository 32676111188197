@import '../../styles/base/variables';

.wrapper {
  width: 100%;
  height: 100%;
  :global {
    .ant-upload-picture-card-wrapper {
      height: 100%;
      .ant-upload.ant-upload-select {
        width: 100%;
        height: 100%;
      }
    }

    .ant-upload {
      color: $gray40;
      font-size: 15px;
      &.ant-upload-select-picture-card {
        width: 100%;
        height: 100%;
        border: 1px dashed $gray84;
        background-color: $alice-blue;
        border-radius: 4px;
        &:hover {
          border-color: $primary-color;
        }
      }
      .saturn-icon {
        width: 20px;
        height: 20px;
        font-size: 20px !important;
        margin-left: 5px;
        svg {
          fill: $gray90;
        }
      }
      .ant-progress-bg {
        background-color: $primary-color;
      }
    }

    .image-content {
      position: relative;
      width: 100%;
      height: 100%;

      &__button-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        button {
          background-color: $red;
          border-color: $red;
          font-size: 11px;
          font-weight: bold;
          height: 40px;
          margin-top: 27px;
          padding: 3px 47px;
          text-transform: uppercase;
        }
      }

      &:hover {
        .image-content__button-wrapper {
          visibility: visible;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }
}
