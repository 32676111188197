.wrapper {
  display: block;
  :global {
    .ant-modal-confirm-title {
      font-weight: bold;
    }
    .ant-modal-confirm-content {
      text-align: justify;
    }
    .ant-modal-confirm-btns {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px;
      .ant-btn + .ant-btn {
        margin-left: 0;
      }
    }
  }
}
