.content {
  padding: 30px 0;
}

.inputsWrapper {
  display: grid;
  grid-gap: 20px;

  > div {
    margin: 0;
  }
  .textArea_wrapper {
    display: grid;
    grid-template-columns: auto 47px;
    align-items: center;
  }
}

.formItem {
  margin: 0;
}

.contactsWrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  > div {
    margin: 0;
  }
}
