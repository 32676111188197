.float-label {
  display: flex;
  flex-direction: column;
  position: relative;
  color: $gray40;

  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  label {
    font-size: 15px;
    line-height: 1.2;
    pointer-events: none;
    position: absolute;
    z-index: 5;
    top: 12px;
    left: 1px;
    padding-left: 12px;
    border-radius: 4px;
    @include transition(all 0.2s);
    padding-top: 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 88%;
  }

  input::placeholder {
    color: transparent;
    font-weight: normal;
  }

  &.active {
    input,
    textarea {
      font-weight: bold;
    }

    label {
      top: 1px;
      color: $gray40;
      font-size: 12px;
    }

    &:focus-within label {
      top: 1px;
      color: $bright-navy-blue;
      font-size: 12px;
    }

    & > input,
    & > textarea,
    & > span {
      & + label {
        width: calc(100% - 18px);
        padding-bottom: 4px;
        background-color: transparent;
      }
    }

    & > textarea {
      & ~ label {
        width: calc(100% - 18px);
        background-color: #fff;
      }
    }

    & > span {
      & + label {
        width: 78%;
      }
    }

    .ant-picker {
      & + label {
        left: 0;
      }
    }

    input::placeholder {
      color: $gray40;
    }

    .ant-input-affix-wrapper {
      .ant-input-prefix {
        transition: 0.3s;
        margin-top: 15px;
        & > div:last-child {
          font-size: 16px;
        }
      }
    }
  }

  &.active.no-label {
    input,
    textarea {
      padding-top: 2px;
      font-weight: 500;
      border-style: solid;
      border-width: 1px;
      &:focus,
      &:focus-visible {
        outline: none;
        border-width: 1px;
        border-color: var(--primary-color);
      }
      & ~ label {
        padding-bottom: 0;
      }
    }
  }

  &.no-label {
    .ant-picker-input {
      input {
        padding-top: 0;
      }
    }
    .ant-picker-range {
      input {
        padding-top: 2px;
        font-weight: 600;
        text-align: center;
        &::placeholder {
          color: $gray40;
        }
        & ~ label {
          padding-bottom: 0;
        }
      }
      .ant-picker-active-bar {
        background: $primary-color;
      }
      &.ant-picker-focused,
      &.ant-picker-status-success {
        .ant-picker-range-separator,
        .ant-picker-suffix {
          .saturn-icon {
            color: $primary-color !important;
          }
        }
      }
    }
  }
}

.ant-form-item {
  margin-bottom: 20px;
  min-width: 1px;

  .ant-picker-focused {
    & + label {
      left: 0;
      top: 1px;
      font-size: 12px;
    }
  }

  &-has-error {
    .ant-input.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
      border-color: $error-color;
    }
    .ant-input,
    .ant-input-affix-wrapper {
      border-color: $error-color;
      &:focus,
      &:hover {
        box-shadow: none;
        border-color: $error-color !important;
      }
    }

    .float-label:focus-within label {
      color: $error-color;
    }

    .ant-select.saturn-select:hover .float-label .label {
      color: $error-color;
    }

    .saturn-image-uploader .ant-upload.ant-upload-select-picture-card {
      border-color: $error-color;
    }

    .ant-radio-wrapper {
      .ant-radio {
        .ant-radio-inner {
          border-color: $error-color;
        }

        &:hover {
          .ant-radio-inner {
            border-color: $error-color;
          }
        }
      }
    }

    .ant-picker.ant-picker-outlined.ant-picker-status-error {
      border-color: $error-color;
      &:hover,
      &:focus {
        border-color: $error-color;
      }
    }
  }

  .ant-form-item-explain {
    line-height: 16px;
    padding: 4px 0;
    min-width: 123px;
    text-align: left;
    &-error {
      color: $error-color;
      font-size: 12px;
    }
  }
}

.ant-input,
.ant-picker {
  width: 100%;
  height: $input-height;
  border-radius: $border-radius-base;
  padding: 15px 10px 0 10px;
  border-color: $gray20;
  font-size: $font-size-body-base;

  &:hover,
  &:focus {
    border-color: $bright-navy-blue;
    box-shadow: none;
  }
}

.ant-input-number {
  width: 100%;
  height: $input-height;
  border-radius: $border-radius-base;
  border-color: $gray20;
  font-size: $font-size-body-base;

  &:hover,
  &:focus {
    border-color: $bright-navy-blue;
    box-shadow: none;
  }
}

.ant-picker {
  padding: 10px;

  &-focused {
    box-shadow: none;
    border: 1px solid $primary-color;
  }

  .ant-picker-input {
    .ant-picker-clear {
      margin-right: 2px;
      & > span[role='img'] {
        font-size: 20px;
      }
    }
    & > input {
      padding-top: 17px;
      &::placeholder {
        color: transparent;
      }
      &:focus {
        &::placeholder {
          color: var(--gray20);
        }
      }
      @media screen and (min-width: 320px) and (max-width: 768px) {
        // if the input font size is less than 16px, IOS behaves weirdly and zooms in when the users focuses on it, which leads to weird behavior
        // https://defensivecss.dev/tip/input-zoom-safari/
        // fixing issue https://support.dataart.com/browse/AADTT-14932
        font-size: 16px;
      }
    }
  }
}

.ant-input {
  @media screen and (max-width: 575px) {
    font-size: 16px;
  }
  &-suffix {
    margin: 0 4px 0 0;
  }

  &-affix-wrapper {
    border-radius: 4px 0 0 4px;
    padding: 0 0 0 12px;
    height: 55px;
    overflow: hidden;

    &-disabled {
      .ant-input[disabled] {
        + .ant-input-suffix {
          svg {
            color: #97999b;
          }
        }
      }
    }

    & > input.ant-input {
      font-size: 16px;
      padding: 15px 0 0;
      height: 53px;
    }

    &:focus,
    &-focused,
    &:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: $bright-navy-blue;
      box-shadow: none;
      & + label {
        z-index: 1;
      }
    }
  }

  &-group {
    &-addon {
      z-index: 3;
      border: 0;
      background-color: transparent;

      .saturn-icon {
        cursor: pointer;
        svg {
          font-size: 0.7em;
          fill: $gray90;
        }
      }

      &:first-child {
        border-radius: $border-radius-base;
      }
    }
  }
}
.ant-input-group-wrapper-outlined {
  &.ant-input-group-wrapper-status-error {
    .ant-input-group-addon {
      border-color: $error-color;
    }
  }
}

@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

textarea {
  &.ant-input {
    min-height: 170px;
    max-height: 170px;
    resize: none;
    padding: 25px 10px 10px;
  }
}

.saturn-country_search {
  &.ant-input-status-error:not(.ant-input-disabled) {
    border-color: $error-color;
    .ant-input-suffix {
      color: $error-color;
    }
  }
  .ant-input-affix-wrapper {
    & > input.ant-input {
      padding: 0;
    }
  }
  > input.ant-input {
    padding: 0;
  }

  .ant-input-suffix {
    margin-right: 10px;
  }
}

.saturn-country_item {
  .ant-select-item-option-content {
    white-space: normal;
    position: relative;
  }

  .saturn-icon {
    position: absolute;
    top: 3px;
    left: 0;
  }

  .saturn-country_text {
    padding-left: 17px;
    word-break: keep-all;
  }
}

.ant-form-item .ant-form-item-control-input {
  min-height: auto;
}

.input-description {
  padding-top: 3px;
  font-size: 13px;
  .saturn-icon {
    padding-right: 2px;
    color: $bright-navy-blue;
  }
}

.ant-picker-dropdown {
  &.aerolab_rangepicker {
    .ant-picker-decade-btn {
      display: none;
    }
    @media screen and (min-width: 575px) {
      min-width: 558px !important;
    }

    .ant-picker-cell {
      padding: 5px 0;

      .ant-picker-cell-inner {
        border-radius: 4px !important;
        &::before {
          border-radius: 4px;
        }
      }

      &.ant-picker-cell-in-view.ant-picker-cell-range-start,
      &.ant-picker-cell-in-view.ant-picker-cell-range-end {
        .ant-picker-cell-inner {
          background: var(--primary-color);
        }
      }

      &.ant-picker-cell-in-view.ant-picker-cell-range-start,
      &.ant-picker-cell-in-view.ant-picker-cell-range-end,
      &.ant-picker-cell-in-view.ant-picker-cell-in-view.ant-picker-cell-in-range {
        &::before {
          background: #1877f2;
          opacity: 0.3;
        }
      }

      &.ant-picker-cell-in-view.ant-picker-cell-range-start-single,
      &.ant-picker-cell-in-view.ant-picker-cell-range-end-single {
        &::before {
          opacity: 0;
        }
      }

      &.ant-picker-cell-in-view.ant-picker-cell-range-hover-end {
        &::after {
          right: 3px;
        }
      }

      &.ant-picker-cell-in-view.ant-picker-cell-range-hover-start {
        &::after {
          left: 3px;
        }
      }

      &.ant-picker-cell-in-view.ant-picker-cell-range-hover-start {
        .ant-picker-cell-inner {
          &::after {
            background: none;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            border-radius: 4px;
            border: 1px dashed #1877f2;
            content: '';
          }
        }
      }

      &:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(
          .ant-picker-cell-range-hover-start
        ):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner {
        background: none;
      }

      &:hover {
        .ant-picker-cell-inner {
          &::after {
            background: none;
          }
          &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            border: 1px dashed #1877f2;
            content: '';
          }
        }
      }
    }

    .ant-picker-panels {
      padding: 0 10px;
      @media screen and (min-width: 320px) and (max-width: 575px) {
        flex-direction: column;
      }
      @media screen and (min-width: 575px) {
        .ant-picker-panel:first-child {
          .ant-picker-body {
            margin-right: 17px;
          }
        }
        .ant-picker-panel:last-child {
          .ant-picker-body {
            margin-left: 17px;
          }
        }
      }
    }

    .ant-picker-date-panel {
      .ant-picker-body {
        padding: 10px 0;
      }
    }
  }
  .ant-picker-panel-container {
    .ant-picker-body {
      .ant-picker-content {
        td.ant-picker-cell {
          color: rgba(0, 0, 0, 0.85);
          &.ant-picker-cell-disabled {
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}

.ant-picker-date-panel,
.range-picker-container {
  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none !important;
  }
}

.range-picker-container {
  &.aerolab_rangepicker {
    .ant-picker-input {
      transition: width 0.1s ease-in-out;
    }

    // 3xl
    &.start-3xl-input > .ant-picker > .ant-picker-input:first-child {
      min-width: 140px;
      width: 140px;
    }
    &.end-3xl-input > .ant-picker > .ant-picker-input:nth-child(3) {
      width: 140px;
    }

    // 2xl
    &.start-2xl-input > .ant-picker > .ant-picker-input:first-child {
      min-width: 135px;
      width: 135px;
    }
    &.end-2xl-input > .ant-picker > .ant-picker-input:nth-child(3) {
      width: 135px;
    }

    // xl
    &.start-xl-input > .ant-picker > .ant-picker-input:first-child {
      min-width: 120px;
      width: 120px;
    }
    &.end-xl-input > .ant-picker > .ant-picker-input:nth-child(3) {
      width: 120px;
    }

    // lg
    &.start-lg-input > .ant-picker > .ant-picker-input:first-child {
      min-width: 115px;
      width: 115px;
    }
    &.end-lg-input > .ant-picker > .ant-picker-input:nth-child(3) {
      width: 115px;
    }

    // md
    &.start-md-input > .ant-picker > .ant-picker-input:first-child {
      min-width: 105px;
      width: 105px;
    }
    &.end-md-input > .ant-picker > .ant-picker-input:nth-child(3) {
      width: 105px;
    }

    // sm
    &.start-sm-input > .ant-picker > .ant-picker-input:first-child {
      min-width: 95px;
      width: 95px;
    }
    &.end-sm-input > .ant-picker > .ant-picker-input:nth-child(3) {
      width: 95px;
    }

    //xs
    &.start-xs-input > .ant-picker > .ant-picker-input:first-child {
      min-width: 90px;
      width: 90px;
    }
    &.end-xs-input > .ant-picker > .ant-picker-input:nth-child(3) {
      width: 90px;
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-body {
    padding: 8px 12px;
  }
  &.datepicker-content {
    .ant-picker-header {
      .ant-picker-header-view {
        order: 0;
        text-align: left;
        display: flex;
        height: 24px;
        align-self: center;
        align-items: center;
        .ant-picker-decade-btn {
          display: none;
        }

        button {
          border: 1px solid var(--primary-color);
          border-radius: 2px;
          color: var(--primary-color);
          padding: 2px 10px;
          line-height: normal;
          font-weight: normal;

          &:hover {
            background-color: var(--primary-color);
            color: var(--white);
          }
        }
      }

      .ant-picker-header-prev-btn {
        order: 1;
        padding: 0 15px;
      }

      .ant-picker-header-next-btn {
        order: 2;
        padding: 0 15px;
      }
    }

    .ant-picker-year-btn {
      word-break: keep-all;
    }

    .ant-picker-month-btn {
      word-break: keep-all;
    }
  }
}
