.content {
  padding: 30px 0;
}

.wrapper {
  display: grid;
  grid-template-columns: 265px auto;
  grid-gap: 20px;

  .image_container {
    height: 225px;
    margin: 0;
  }
}
.inputsWrapper {
  display: grid;
  grid-gap: 20px;
  width: 100%;

  > div {
    margin: 0;
  }

  .textArea_wrapper {
    display: grid;
    align-items: center;
  }
}

.formItem {
  margin: 0;
}
