@import '../../styles/base/variables';

.wrapper {
  :global {
    .saturn-checkbox-read-only {
      .ant-checkbox-disabled {
        cursor: pointer;
        & + span {
          cursor: pointer;
          color: inherit;
        }
      }
      .ant-checkbox {
        &.ant-checkbox-checked {
          &.ant-checkbox-disabled {
            .ant-checkbox-inner {
              background-color: $primary-color;
              border-color: $primary-color !important;
            }
          }
          .ant-checkbox-inner {
            background-color: $primary-color;
            border-color: $primary-color;
            &::after {
              border-color: var(--gray10);
            }
          }
        }
      }
    }
    .saturn-checkbox-title {
      display: inline-block;
      margin: 0 5px;
    }
    .ant-checkbox {
      align-self: flex-start;
      & + span {
        padding: 0 10px;
      }
      &-input {
        border-radius: 1px;
      }
      &-inner {
        width: 20px;
        height: 20px;
        border-radius: 1px;
      }
      &.ant-checkbox-checked {
        &.ant-checkbox-disabled {
          .ant-checkbox-inner {
            background-color: $gray10;
            border-color: $gray20;
          }
        }
        .ant-checkbox-inner {
          background-color: $primary-color;
          border-color: $primary-color;

          &::after {
            width: 7px;
            height: 11px;
          }
        }
      }
      &.ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          background-color: transparent;

          &::after {
            background-color: transparent;
          }
        }
        &.ant-checkbox-checked .ant-checkbox-inner::after {
          background-color: $primary-color;
          width: 8px;
          height: 8px;
        }
      }
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: $primary-color;
    }
  }
}
