.toggleWrapper {
  display: flex;
  gap: 16px;
}

.toggleLabel {
  display: flex;
}

.label {
  font-weight: 700;
  font-size: 16px;
}

.dateTimeBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  :global {
    .saturn-time-picker {
      .ant-picker-suffix {
        margin-right: 2px;
        & > span[role='img'] {
          font-size: 20px;
        }
      }
      .ant-picker-footer-extra {
        padding: 0;
      }
    }
  }
}

.displayCountdownBlock {
  margin-top: 16px;
}
