$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

@mixin _mq($breakpoint-value) {
  @if $breakpoint-value == 0 {
    @content;
  } @else {
    @media (min-width: $breakpoint-value) {
      @content;
    }
  }
}

@mixin mq($breakpoint-name) {
  $breakpoint-value: map-get($breakpoints, $breakpoint-name);

  @if not $breakpoint-value or $breakpoint-value == 0 {
    @content;
  } @else {
    @media (min-width: $breakpoint-value) {
      @content;
    }
  }
}
