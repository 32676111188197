@import '../styles/variables';

.stickyContainer {
  display: flex;
  position: relative;
  height: 100%;
}

.content {
  flex: 1 1 auto;

  :global {
    .saturn-collapse {
      .ant-collapse-item {
        box-shadow: none;

        .ant-collapse-header {
          background: $gray15;
          border-top: 2px solid $primary-color;
        }
      }
    }
  }
}

.stickyBlock {
  flex: 0 0 300px;
  position: sticky;
  top: 40px;
  align-self: flex-start;
  padding: 0 20px;
  button[type='submit'] {
    width: 100%;
    margin-bottom: 16px;
  }
}

.formWrapper {
  :global {
    .float-label.active {
      & > input + label,
      textarea + label,
      span + label {
        background-color: transparent;
      }
    }
  }
}

.buttonContainer {
  margin-bottom: 20px;
}

.sidebarCardWrapper {
  padding: 20px !important;
  box-shadow: none !important;
  border: 1px solid $gray15;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 20px !important;
  }

  :global {
    .ant-card-head,
    .ant-card-body {
      padding: 0;
    }

    .ant-card-head {
      border-bottom: none;
      min-height: auto;
    }

    .ant-card-body {
      .ant-btn {
        width: 100%;
      }
    }

    .ant-card-head-title {
      font-family: $font-family-base;
      font-size: 16px;
      line-height: 24px;
      color: $gray90;
      padding-top: 0;
    }
  }
}
