@import '../../../../styles/variables';

.content {
  padding: 30px 0;
}

.title {
  font-size: $font-size-body-large;
  font-family: $font-family-base;
  background: $gray10;
  padding: 14px 20px;
}

.wrapper {
  display: grid;
  grid-template-columns: 265px auto;
  grid-gap: 20px;
  > div.image_container {
    height: 225px;
    margin: 0;
  }
}
.inputsWrapper {
  display: grid;
  grid-gap: 20px;
  width: 100%;
  > div {
    margin: 0;
  }
  .textArea_wrapper {
    display: grid;
    align-items: center;
  }
}

.formItem {
  margin: 0;
}
