@import '../../styles/base/variables';

.wrapper {
  :global {
    button {
      &.button_file-uploader {
        background: $alice-blue;
        color: $primary-color;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        width: 140px;
        height: 40px;
        border: none;

        &:focus {
          background: $alice-blue;
          color: $primary-color;
        }

        &:hover {
          background: $royal-blue-dark;
          color: $white;
        }
      }
    }
    .ant-upload-list-item-name {
      white-space: normal;
    }
  }
}
