@import 'styles/variables';

.wrapper {
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  gap: 16px;
  min-width: 150px;
  :global {
    .saturn-button {
      flex-basis: 30px;
      width: 30px;
      height: 30px;
      padding: 3px 0 0 0;
      border-radius: 50%;
      align-self: center;
    }
    .ant-input-number-input-wrap {
      height: 100%;
      .ant-input-number-input {
        height: inherit;
        font-weight: 600 !important;
        text-align: center;
        border-color: transparent;
        &:focus {
          border-color: transparent !important;
        }
      }
    }
  }
}
