@import 'styles/variables';

.common {
  white-space: pre-line;
  line-height: normal;
  display: grid; // workaround for tables having huge space before them
  overflow-x: auto; // to make table scrollable

  ul {
    line-height: 1;
  }

  table {
    border: 1px solid $gray15;
    table-layout: fixed;
    width: 100%;

    thead {
      th {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: left;
        border-bottom: 1px solid $gray15;
      }
    }

    tbody {
      td {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.15px;
        text-align: left;
      }

      tr:not(:last-child) td {
        border-bottom: 1px solid $gray15;
      }
    }

    td,
    th {
      width: 147px;
    }

    th:first-child,
    td:first-child {
      padding-left: 15px;
    }

    th:last-child,
    td:last-child {
      padding-right: 15px;
    }
  }
}

.textTransformed {
  table {
    thead {
      th {
        text-transform: uppercase;
      }
    }
    tbody {
      td {
        text-transform: capitalize;
      }
    }
  }
}
