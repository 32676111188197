// Usage:   @include transition(width, height 0.3s ease-in-out);
// Output:  -webkit-transition(width 0.2s, height 0.3s ease-in-out);
//          transition(width 0.2s, height 0.3s ease-in-out);
//
// Pass in any number of transitions
@mixin transition($transitions...) {
  $unfoldedTransitions: ();
  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
  }

  -webkit-transition: $unfoldedTransitions;
  transition: $unfoldedTransitions;
}

@function unfoldTransition($transition) {
  // Default values
  $property: all;
  $duration: 0.5s;
  $easing: ease-in; // Browser default is ease
  $delay: null; // Browser default is 0
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i);
    } @else {
      $p: nth($defaultProperties, $i);
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}
