@import '../../../styles/variables';

.switch {
  font-weight: normal;
  font-size: $font-size-body-small;
  font-family: $font-family-base;
  display: flex;
  align-items: baseline;
}
.toggler {
  margin: 0 !important;
  position: relative;
  z-index: 2;
}
.switchText {
  margin: 0 10px 0 0;
}
