$alert-prefix-cls: 'ant-alert';
.wrapper {
  margin-bottom: 25px;
  :global {
    .#{$alert-prefix-cls} {
      margin-bottom: 10px;
      padding: 7px 10px;
      background-color: transparent;
      &-info {
        border: 1px solid var(--bright-navy-blue);
        .#{$alert-prefix-cls}-message,
        .#{$alert-prefix-cls}-description {
          color: var(--bright-navy-blue);
        }
      }
      &-success {
        border: 1px solid var(--shamrock-green);
        .#{$alert-prefix-cls}-message,
        .#{$alert-prefix-cls}-description {
          color: var(--shamrock-green);
        }
      }
      &-error {
        border: 1px solid var(--red);
        .#{$alert-prefix-cls}-message,
        .#{$alert-prefix-cls}-description {
          color: var(--red);
        }
      }
      &-warning {
        border: 1px solid var(--tangerine);
        .#{$alert-prefix-cls}-message,
        .#{$alert-prefix-cls}-description {
          color: var(--tangerine);
        }
      }
      &-message,
      &-description {
        line-height: 24px;
        font-weight: bold;
        white-space: pre-line;
      }
    }
  }
}
