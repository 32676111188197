.content {
  padding: 30px 0;
}

.title {
  font-size: var(--font-size-body-large);
  font-family: var(--font-family-base);
  background: var(--gray10);
  padding: 14px 20px;
}

.inputsWrapper {
  display: grid;
  grid-gap: 20px;

  > div {
    margin: 0;
  }
  .textArea_wrapper {
    display: grid;
    grid-template-columns: auto 47px;
    align-items: center;
  }
}

.formItem {
  margin: 0;
}
