@import '../../../styles/variables';

.content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  margin: 30px 0;

  :global {
    .saturn-content-block-container {
      .saturn-content-block {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.flexItem {
  width: 100%;
  display: flex;
  gap: 16px;
}

.label {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-weight: 700;
}

.inputsWrapper {
  display: flex;
  gap: 28px;
  width: 100%;
  > * {
    flex: 1 1 50%;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $font-size-body-large;
  font-family: $font-family-base;
  background: $gray10;
  padding: 14px 20px;
  margin-bottom: 0;
}
