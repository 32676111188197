@import '../../styles/base/variables';

.wrapper {
  :global {
    .ant-switch {
      background-color: $gray5;
      letter-spacing: 1px;
      &.ant-switch-checked:hover:not(.ant-switch-disabled) {
        background-color: $shamrock-green;
      }
      &.ant-switch-checked {
        background-color: $shamrock-green;
      }
    }
  }
}
