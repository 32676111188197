@import 'styles/variables';

.timePickerTitles {
  display: grid;
  div {
    padding-left: 15px;
    &:not(:first-child) {
      &::before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 15px;
        background: $gray15;
        transform: translateX(-15px) translateY(3px);
      }
    }
  }
}

.nowButtonContainer {
  padding: 4px 12px;
  border: 1px solid $gray15;
  button {
    width: 100%;
  }
}

.timePickerContainer {
  :global {
    .ant-picker-ok {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}
