@import '../../../styles/variables';

.navBar {
  padding: 31px 30px;
  background-color: $royal-blue-dark;
  align-items: stretch;
  height: 100%;
  position: fixed;
  width: 260px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background: $gray30;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    background: $white;
    cursor: pointer;
  }
  & {
    scrollbar-face-color: $gray30;
    scrollbar-track-color: $white;
  }

  :global {
    .saturn-select {
      margin-bottom: 33px;
    }
    .ant-menu {
      margin: 0;
      background: none;

      .ant-menu-item-group-title {
        font-size: 10px;
        text-transform: uppercase;
        color: $white;
        opacity: 0.4;
        letter-spacing: 1px;
        padding: 0 0 8px;
        line-height: normal;
      }
      .ant-menu-item-group {
        padding-bottom: 19px;
      }
      .ant-menu-item {
        padding: 0;
        line-height: normal;
        height: auto;
        position: relative;
        padding-inline: unset !important;

        .saturn-icon {
          position: absolute;
          top: 8px;
          left: 8px;
        }
        svg {
          fill: $white;
          transform: rotate(180deg);
        }

        a {
          color: $white;
          display: block;
          padding: 5px 10px 6px 10px;

          &:hover {
            background-color: $bright-navy-blue;
          }
          &.active {
            background-color: $bright-navy-blue;
            padding-left: 30px;
          }
        }
        &.ant-menu-item-selected {
          background-color: transparent;
        }
      }
      .ant-menu-item-divider {
        background-color: $dark-blue-gray;
        margin: 0 0 26px;
      }
    }
    .ant-menu-vertical {
      border-right: 0;
    }
    .logo {
      display: block;
      width: 115px;
      margin: 0 auto 28px;
    }
  }
}
