.saturnSpinWrapper {
  .saturnSpin {
    @media screen and (min-width: 320px) and (max-width: 768px) {
      &:global {
        &.ant-spin.ant-spin-spinning {
          position: fixed;
          background: rgb(110 110 110 / 15%);
          bottom: 0;
          z-index: 10;
          max-height: initial;
        }
      }
    }

    svg {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
}
