.flexWrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 16px;
}

.flexItem {
  width: 100%;
}

.flexWrapperInner {
  display: flex;
  align-items: baseline;
  gap: 16px;
}

.label {
  font-weight: 700;
  font-size: 16px;
}

.fullWidth {
  width: 100%;
}
