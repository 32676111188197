.ant-radio-disabled {
  &.ant-radio-checked {
    .ant-radio-inner {
      border-color: #006fcf !important;
      opacity: 0.5;
    }
  }
  .ant-radio-inner {
    border-color: #d9d9d9 !important;
    cursor: not-allowed;
    &:after {
      background-color: #fff;
    }
  }
}
