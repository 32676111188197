@import 'styles/variables';

.toggleWrapper {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  background-color: $gray10;
  padding: 14px 20px;
  margin-bottom: 20px;
  p {
    margin-bottom: 0;
  }
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.label {
  font-weight: 700;
  font-size: 16px;
}
