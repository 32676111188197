@font-face {
  font-family: 'BentonSans';
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: url(../../fonts/BentonSans-Light.woff) format('woff');
}

@font-face {
  font-family: 'BentonSans';
  font-weight: 400;
  font-display: swap;
  src: url(../../fonts/BentonSans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'BentonSans';
  font-weight: 500;
  font-display: swap;
  src: url(../../fonts/BentonSans-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Guardian';
  src: url(../../fonts/Guardian-Egyptian-Web-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}
