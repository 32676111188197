@import '../../../../styles/variables';

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $font-size-body-large;
  font-family: $font-family-base;
  background: $gray10;
  padding: 14px 20px;
}

.image_container {
  display: block;
}
