.content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  margin-bottom: 20px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
