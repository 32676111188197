@import 'styles/variables';

.toggleWrapper {
  display: flex;
  gap: 16px;
  align-items: flex-start;
}

.toggleLabel {
  display: flex;
}

.label {
  font-weight: 700;
  font-size: 16px;
}

.dateTimeBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  :global {
    .saturn-time-picker {
      .ant-picker-suffix {
        margin-right: 2px;

        & > span[role='img'] {
          font-size: 20px;
        }
      }

      .ant-picker-footer-extra {
        padding: 0;
      }
    }
  }
}

.displayCountdownBlock {
  margin-top: 16px;
}

.callToActionHeader {
  background-color: $gray10;
  margin-bottom: 10px;
  display: flex;
  padding: 20px 0 20px 10px;

  > p {
    color: $gray90;
    font-size: 18px;
    margin: 0 21px 0 0;
    font-weight: bold;
  }
}
