@import '../../styles/base/variables';
@import '../../styles/base/transitions';
@import '../../styles/base/font-sizes';

.saturnCollapse {
  :global {
    .ant-collapse-item {
      padding-bottom: 0;
      margin-bottom: 20px;
      word-break: break-word;
      box-shadow: $box-shadow;
      @include transition(box-shadow 0.2s, padding-bottom 0.2s);

      .ant-collapse-header {
        @include fluid-type($min-vw, $max-vw, 22px, 26px);
        padding-left: 30px;
        position: relative;
        letter-spacing: -0.075px;
        margin: 0 !important;
        font-family: $font-family-heading-base;
        background: $gray10;
        box-sizing: content-box;
        border-top: 2px solid $primary-color;
        border: 0;
        font-weight: normal;
        line-height: 38px;
        display: flex;
        align-items: center;
        @include transition(margin-bottom 0.2s);

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 50px;
          height: 100%;
          @include transition(background-color 0.2s);
        }

        @media screen and (min-width: 320px) and (max-width: 768px) {
          padding: 10px;
          line-height: 1;
        }
      }

      .ant-collapse-extra {
        position: absolute;
        top: 12px;
        right: 10px;
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          text-align: justify;
          padding: 0;
        }
      }

      &-active {
        padding-bottom: 3px;
      }
    }
  }
}
