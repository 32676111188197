@import '../../../styles/variables';

.tooltipWrapper {
  padding: 10px 20px;
  display: flex;
  align-items: flex-start;
  background: $gray10;
  justify-content: flex-end;

  .tooltipIcon {
    margin-right: 8px;
    .saturn-icon {
      fill: red;
    }
  }
}
