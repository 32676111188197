@import '../../../../styles/variables';

.wrapper {
  padding-left: 30px;
}

.content {
  padding: 30px 0;
}

.buttonTitle {
  font-size: 12px;
  color: $gray40;
  margin-bottom: 2px;
}
.inputsWrapper {
  display: grid;
  grid-gap: 20px;
  width: 100%;
  > div {
    margin: 0;
  }
}
