@import '../../styles/base/variables';
@import '../../styles/breakpoints/breakpoints';

.wrapper {
  :global {
    .ant-tabs {
      &-nav {
        &-list {
          background-color: $gray10;
          border-top: 1px solid $gray15;
          display: block;
          justify-content: space-between;
          flex-direction: column;
          width: 100%;

          @include mq(md) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
        &-wrap {
          white-space: normal;
        }
        &-operations {
          display: none !important;
        }
        .ant-tabs-ink-bar {
          background-color: transparent;
          bottom: inherit;
          top: auto;
          height: 4px;
          @include mq(md) {
            background-color: $primary-color;
          }
        }
      }
      &-tab {
        border-bottom: 1px solid $gray15;
        display: block;
        color: $gray90;
        font-family: $font-family-base;
        flex: 1 1 0;
        padding: 21px 21px 14px;
        overflow: hidden;
        margin: 0 !important;

        @include mq(md) {
          border-left: 1px solid $gray15;
          padding: 21px 0 14px;

          &:first-of-type {
            border-left: 1px solid $gray10;
          }
          &.ant-tabs-tab-active {
            border-bottom: 1px solid $white;

            &:first-of-type {
              border-left: 1px solid $white;
            }
          }
        }

        &:hover,
        &:focus {
          .ant-tabs-tab-btn {
            color: $primary-color;
          }
        }

        &.ant-tabs-tab-active {
          background-color: $white;
          .ant-tabs-tab-btn {
            color: $primary-color;
          }
        }
        &-btn {
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}
