.content {
  display: grid;
  grid-gap: 20px;
  padding: 30px 0;
}
.togglersContnet {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 30px 0;
}
.toggleWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 64px;
  margin: 20px 0 10px;

  label {
    margin-bottom: 20px;
  }
}
.textAreaWrapper {
  display: grid;
  grid-template-columns: auto 47px;
  align-items: center;
}
